<template>
  <v-dialog
    :value="show"
    width="400"
    @click:outside="cancel"
    @keydown="handleKeydown"
  >
    <v-card>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card-title>Ad-hoc-Konferenz starten</v-card-title>

        <v-card-text>
          <conference-subject-input
            v-model="conferenceSubject"
          ></conference-subject-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <temeno-circle-button
            action
            title="abbrechen"
            @click="cancel"
          >
            $close
          </temeno-circle-button>

          <temeno-circle-button
            action
            title="starten"
            type="submit"
            :disabled="!valid || submitting"
            :loading="submitting"
          >
            {{ !valid || submitting ? '$approve_action_disabled' : '$approve' }}
          </temeno-circle-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api";

export default {
  name: "AdHocDialog",
  components: {
    ConferenceSubjectInput: () => import("@/components/ConferenceSubjectInput"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      conferenceSubject: "",
      submitting: false,
      valid: false,
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.reset();
    },
    handleKeydown(key) {
      if (key.code === "Escape") {
        this.cancel();
      }
    },
    reset() {
      this.conferenceSubject = "Ad-hoc-Konferenz";
    },
    submit() {
      this.submitting = true;
      const conference = {
        subject: this.conferenceSubject,
        participants: [],
      };
      api.createConference(conference).then((response) => {
        const { location } = response.headers;
        const id = Number(location.substring(location.lastIndexOf("/") + 1));
        this.$emit("submit");
        this.$router.push({
          name: "conference",
          params: {
            id,
          },
        });
      });
    },
  },
  i18n: {
    messages: {
      de: {
        submit: "Konferenz starten",
        cancel: "Abbrechen",
      },
    },
  },
};
</script>
